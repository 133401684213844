// import * as XLSX from 'xlsx';
import XLSX from 'xlsx-js-style';
export default function ExporttoExcel(data, name, merges) {
	//加标题
	const header = [[{ v: name, s: { alignment: { horizontal: 'center', vertical: 'center' }, font: { bold: true, sz: 16 } } }]];
	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.json_to_sheet(header.concat(data), { skipHeader: merges != undefined && merges.length > 0 });
	if (merges != undefined && merges.length > 0) {
		// s 表示要合并的单元格范围的左上角单元格，r 表示该单元格的行号，c 表示该单元格的列号，行列号从 0 开始计数。所以 { r: 0, c: 0 } 表示第 1 行第 1 列的单元格，即 A1 单元格。
		// e 表示要合并的单元格范围的右下角单元格，其含义与 s 相同。所以 { r: 0, c: 1 } 表示第 1 行第 2 列的单元格，即 B1 单元格。
		// 因此，{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } } 表示要合并第 1 行第 1 列和第 1 行第 3 列之间的元格。
		// 注意，合并会以开始位置单元格中的内容为准，所以合并后的单元格中的内容为 A1 单元格中的内容。
		// worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
		worksheet['!merges'] = merges;
		//设置样式
		// worksheet['A1'].s = {
		// 	font: { bold: true, sz: 40 },
		// 	alignment: { horizontal: 'center', vertical: 'center' }
		// };
	}
	XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
	XLSX.writeFile(workbook, `${name}.xlsx`);
}
